import { generateRandomNumber, generateRandomString, getNow } from '@utils/common';
import constant from '@utils/constant';
import useEventInfo, {
   EventInfoContext,
} from '@views_admin/event-info/hooks/useEventInfo';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import IconArrow16Bold from '@icons/IconArrow16Bold';
import { Modal } from 'antd';
import EventInfo from '@views_admin/event-info/EventInfo';
import { createCancelToken } from '@apis/ServiceController';
import { eventService } from '@services/eventService';
import { message } from '@components/Message';
import { buildInitialDataModel } from '@views_admin/event-info/common';
import ContentLoading from '@views_admin/ContentLoading';
import SEO from '@components/SEO';

const EventDetails = memo((props) => {
   const uid = props.id;
   const [loading, setLoading] = useState(false);
   const {
      hasDataChanged,
      initialvalues,
      setInitialData,
      submitting,
      onSubmit,
      ...eventStates
   } = useEventInfo(uid);
   const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
   const cancelToken = useRef();

   useEffect(() => {
      const loadData = async () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
         await setLoading(true);
         cancelToken.current = createCancelToken();
         const response = await eventService.getEventDetails(
            uid,
            cancelToken.current?.token
         );
         console.log('response', response);
         if (response.isSuccess) {
            const initialData = buildInitialDataModel(response.data);
            console.log(initialData);
            setInitialData(initialData);
            setLoading(false);
         } else {
            message.error(response.message);
            navigate(constant.ROUTE_ADMIN_EVENTS);
         }
      };
      loadData();
      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [uid, setInitialData]);

   const goBack = () => {
      if (!hasDataChanged) {
         navigate(constant.ROUTE_ADMIN_EVENTS);
      } else {
         setIsConfirmationOpen(true);
      }
   };

   const cancelLeavePage = useCallback(() => {
      setIsConfirmationOpen(false);
   }, []);

   const confirmLeavePage = useCallback(() => {
      setIsConfirmationOpen(false);
      navigate(constant.ROUTE_ADMIN_EVENTS);
   }, []);

   const { name } = initialvalues;

   return (
      <>
         <SEO title={'Events - Admin'} />
         <EventInfoContext.Provider
            value={{
               ...eventStates,
            }}
         >
            <ContentLoading isLoading={loading} />
            <div className="admin-product-details">
               <div className="content-head admin-product-details__header">
                  <div className="content-head__left">
                     <label className="title">
                        <i onClick={goBack}>
                           <IconArrow16Bold />
                        </i>
                        {name && name}
                     </label>
                  </div>
                  <div className="content-head__right admin-actions-group">
                     <button className="cancel" onClick={goBack}>
                        Cancel
                     </button>
                     <button className="submit" onClick={onSubmit} disabled={submitting}>
                        Save
                     </button>
                  </div>
               </div>
               <div className="content-body">
                  <EventInfo />
               </div>
            </div>
         </EventInfoContext.Provider>
         <Modal
            className="modal-confirm-leave-page"
            wrapClassName="modal-confirm-leave-page-container"
            title={null}
            visible={isConfirmationOpen}
            closable={false}
            maskClosable={true}
            onOk={confirmLeavePage}
            onCancel={cancelLeavePage}
            destroyOnClose={true}
            width={320}
            zIndex={4039}
            footer={
               <div className="modal-confirm-leave-page__footer">
                  <button className="cancel" onClick={cancelLeavePage}>
                     Stay
                  </button>
                  <button className="confirm" onClick={confirmLeavePage}>
                     Leave
                  </button>
               </div>
            }
         >
            <div className="modal-confirm-leave-page__content">
               <label>Confirm to leave page</label>
               <p>Changes you made may not be saved.</p>
            </div>
         </Modal>
      </>
   );
});

export default EventDetails;
